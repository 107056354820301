// Generated by Framer (e1a43d5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["N0FqKEqvD", "Z9gbQ3nEV", "OSe40oS4t", "wBp92QqlC"];

const serializationHash = "framer-0weW9"

const variantClassNames = {N0FqKEqvD: "framer-v-a1n1hq", OSe40oS4t: "framer-v-1qi6off", wBp92QqlC: "framer-v-1o61h7r", Z9gbQ3nEV: "framer-v-vtmhbg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "N0FqKEqvD", "Variant 2": "Z9gbQ3nEV", "Variant 3": "OSe40oS4t", "Variant 4": "wBp92QqlC"}

const getProps = ({casestudy, click, cursor, height, id, link, width, ...props}) => { return {...props, EQm5oN0jS: click ?? props.EQm5oN0jS, iSu5EFlwk: cursor ?? props.iSu5EFlwk, nx_7bQXLJ: casestudy ?? props.nx_7bQXLJ ?? {src: "https://framerusercontent.com/images/qiM5lQnZ6CJDkkGueo5ni2wHZsQ.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/qiM5lQnZ6CJDkkGueo5ni2wHZsQ.png?scale-down-to=512 512w,https://framerusercontent.com/images/qiM5lQnZ6CJDkkGueo5ni2wHZsQ.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/qiM5lQnZ6CJDkkGueo5ni2wHZsQ.png 1120w"}, rcMnEkbmy: link ?? props.rcMnEkbmy, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "N0FqKEqvD"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;casestudy?: {src: string; srcSet?: string; alt?: string};link?: string;cursor?: string;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, nx_7bQXLJ, rcMnEkbmy, iSu5EFlwk, EQm5oN0jS, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "N0FqKEqvD", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1i9mu79 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (EQm5oN0jS) {const res = await EQm5oN0jS(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={rcMnEkbmy} motionChild nodeId={"N0FqKEqvD"} openInNewTab={false} scopeId={"iDS7Np2Y9"}><Image {...restProps} {...gestureHandlers} as={"a"} background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 688, pixelWidth: 1120, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(nx_7bQXLJ)}} className={`${cx(scopingClassNames, "framer-a1n1hq", className, classNames)} framer-1awvx8c`} data-framer-cursor={iSu5EFlwk} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"N0FqKEqvD"} onTap={onTap1i9mu79} ref={refBinding} style={{...style}} {...addPropertyOverrides({OSe40oS4t: {"data-framer-name": "Variant 3"}, wBp92QqlC: {"data-framer-name": "Variant 4"}, Z9gbQ3nEV: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0weW9.framer-1awvx8c, .framer-0weW9 .framer-1awvx8c { display: block; }", ".framer-0weW9.framer-a1n1hq { cursor: pointer; height: 172px; position: relative; text-decoration: none; width: 280px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 172
 * @framerIntrinsicWidth 280
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Z9gbQ3nEV":{"layout":["fixed","fixed"]},"OSe40oS4t":{"layout":["fixed","fixed"]},"wBp92QqlC":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"nx_7bQXLJ":"casestudy","rcMnEkbmy":"link","iSu5EFlwk":"cursor","EQm5oN0jS":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameriDS7Np2Y9: React.ComponentType<Props> = withCSS(Component, css, "framer-0weW9") as typeof Component;
export default FrameriDS7Np2Y9;

FrameriDS7Np2Y9.displayName = "testimonials-image";

FrameriDS7Np2Y9.defaultProps = {height: 172, width: 280};

addPropertyControls(FrameriDS7Np2Y9, {variant: {options: ["N0FqKEqvD", "Z9gbQ3nEV", "OSe40oS4t", "wBp92QqlC"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4"], title: "Variant", type: ControlType.Enum}, nx_7bQXLJ: {__defaultAssetReference: "data:framer/asset-reference,qiM5lQnZ6CJDkkGueo5ni2wHZsQ.png?originalFilename=testimonials-1.png&preferredSize=auto", title: "Casestudy", type: ControlType.ResponsiveImage}, rcMnEkbmy: {title: "Link", type: ControlType.Link}, iSu5EFlwk: {title: "Cursor", type: ControlType.CustomCursor}, EQm5oN0jS: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FrameriDS7Np2Y9, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})